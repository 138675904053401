import React from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { createResourceLocatorString } from '../../../util/routes';
import {
  validUrlQueryParamsFromProps,
  cleanSearchFromConflictingParams,
} from '../SearchPage.shared';

import css from './ResultsPerPage.module.css';

const ResultsPerPage = props => {
  const {
    className,
    rootClassName,
    allResultCounter,
    currentResultCounter,
    history,
    location,
    routeConfiguration,
    config,
    currentQueryParams
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  const onChangeShowListingsNumber = (e) => {

    const { listingFields: listingFieldsConfig } = config?.listing || {};
    const { defaultFilters: defaultFiltersConfig, sortConfig } = config?.search || {};

    const urlQueryParams = validUrlQueryParamsFromProps(props);
    const searchParams = { ...urlQueryParams, ...currentQueryParams, perPage: e.target.value };
    const search = cleanSearchFromConflictingParams(
      searchParams,
      listingFieldsConfig,
      defaultFiltersConfig,
      sortConfig,
    );

    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, search));
    // this.setState({ isFilterModalOpen: false });
  }

  const selectOptions = [
    { key: "10", label: "Show 10" },
    { key: "20", label: "Show 20" },
    { key: "50", label: "Show 50" },
    { key: "100", label: "Show 100" }
  ];

  return (
    <div className={classes}>
      <div className={css.counterText}>
        {`Showing ${currentResultCounter} of ${allResultCounter} entries`}
      </div>
      <select
        className={css.counterDropdown}
        name="showListingsNumber"
        id="showListingsNumber"
        onChange={(e) => onChangeShowListingsNumber(e)}
      >
        {selectOptions.map(option => {
          const isSelected = currentQueryParams?.perPage === option?.key

          return (
            <option
              key={option?.key}
              value={option?.key}
              selected={isSelected}
            >
              {option?.label}
            </option>
          )
        })}
      </select>
    </div>
  );
};

ResultsPerPage.defaultProps = {
  className: null,
  rootClassName: null,
};

ResultsPerPage.propTypes = {
  className: string,
  rootClassName: string,
};

export default ResultsPerPage;
